import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { removeSpacesFromHtmlHref } from '../../../domain/utils/stringUtils';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { dateService } from '../../../domain/services/dateService';

const styles = {
  container: css`
    h2 {
      font-size: ${theme.font.size('xl')};
      margin-bottom: ${theme.spacing('xs')};
      color: #555;
    }
    h2.h3 {
      font-size: ${theme.font.size('l')};
    }
    border: 2px solid rgb(230 230 230);
    margin-bottom: ${theme.spacing('s')};
    padding: ${theme.spacing.parse('$base $s')};
    ${theme.mq.small_desktop} {
      margin-bottom: ${theme.spacing('xs2')};
      padding: ${theme.spacing.parse('$l $base')};
    }
    p:last-child {
      margin-bottom: 0;
    }
  `
};

const AuthorBoxNotesInner = ({ data, content }) => {
  const startDate = data.startDate?.timestamp;
  const endDate = data.endDate?.timestamp;
  const containsExpireDates = startDate || endDate;
  const [isVisible, setIsVisible] = useState(!containsExpireDates);

  useEffect(() => {
    if (startDate || endDate) {
      const isWithinDateRange = dateService.isWithinDateRange(
        Math.floor(new Date().getTime() / 1000),
        startDate,
        endDate
      );
      if (isWithinDateRange) {
        setIsVisible(true);
      }
    }
  }, [startDate, endDate]);

  if (isVisible && data.content?.value) {
    let dataString = data.content.value;
    dataString = removeSpacesFromHtmlHref(dataString);

    if (content.branded) {
      dataString = dataString.replace(
        'rel="nofollow noopener noreferrer"',
        'rel="nofollow noopener sponsored"',
        dataString
      );
    }
    return (
      <div
        className="paragraph"
        css={styles.container}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: dataString }}
      />
    );
  }
  return <div />;
};

AuthorBoxNotesInner.defaultProps = {
  content: undefined
};

AuthorBoxNotesInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  content: PropTypes.objectOf(PropTypes.any)
};

export const AuthorBoxNotes = ParagraphWrapper(AuthorBoxNotesInner);
